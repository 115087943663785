<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <img src="../img/logo.png" alt="" class="w-44">
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Blank</ion-title>
        </ion-toolbar>
      </ion-header>
    
      <div v-if="documents === true">
        <div class="flex flex-col justify-center w-full items-center p-20 text-center">
          <p class="text-xl font-bold">No hay ninguna firma pendiente. 🤔</p>
          <p class="text-sm">Por favor, envie un documento primero para poder firmar en su programa de doota.</p>
          <button @click="recargar" class="btn my-5">Recargar <i class="fa-solid fa-rotate ml-2"></i></button>
        </div>
      </div>
      <div v-if="documents === false" class="m-5 p-5">
        <p class="font-bold text-sm my-4"><i class="fa-solid fa-signature"></i> Documentos para firmar</p>
        <div class="overflow-x-auto">
          <table class="table w-full">
            <!-- head -->
            <thead>
              <tr>
                <th></th>
                <th><i class="fa-solid fa-user"></i> Para</th>
                <th><i class="fa-solid fa-database"></i> Datos</th>
                <th><i class="fa-solid fa-filter"></i> Opciones</th>
              </tr>
            </thead>
            <tbody>
              <!-- row 1 -->
              <tr v-for="(sign,index,i) in data" :key = "sign" class="text-sm">
                <th v-if="sign.sign === false">{{i}}</th>
                <td v-if="sign.sign === false">
                  <div class="flex flex-col">
                    <p class="text-xs italic">ID: {{sign.id}}</p>
                    <p class="font-bold">NIE: {{sign.nie}}</p>
                  </div>
                </td>
                <td v-if="sign.sign === false">
                  <div class="flex flex-col">
                    <p>Fecha: {{sign.fecha}}</p>
                    <p v-if="sign.sign === false">Firmado: <span class="font-bold">No</span></p>
                    <p v-if="sign.sign === true">Firmado: <span class="font-bold">Si</span></p>
                    <p>Observaciones: {{sign.observations}}</p>
                  </div>
                </td>
                <td v-if="sign.sign === false">
                  <a :href="sign.link" target="_blank" class="btn bg-white text-green-600 border-green-600 btn-sm m-1 shadow"><i class="fa-regular fa-eye mr-2"></i> Ver</a>
                  <label @click="firmar(sign.nie, index, sign.fecha, sign.idFile)" for="my-modal-2" class="btn bg-green-600 border-none btn-sm m-1 shadow"><i class="fa-solid fa-signature mr-2"></i> Firmar</label>
                  <button class="btn bg-red-100 text-red-600 shadow border-none btn-sm m-1 hover:bg-red-200"><i class="fa-solid fa-x mr-2"></i> Cancelar</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
        <input type="checkbox" id="my-modal-2" class="modal-toggle" />
        <div class="modal">
          <div class="modal-box">
            <div class="flex flex-col items-center justify-center">
              <h3 class="font-bold text-lg"><i class="fa-solid fa-file-signature"></i> Introduzca su firma dentro del marco:</h3>
              <canvas id="canvas" height="200" width="300" class="w-max border"></canvas>
            </div>
            <div class="modal-action">
              <label @click="guardarFirma" for="my-modal-2" class="btn btn-sm bg-green-600 border-none">Firmar</label>
              <label @click="limpiarFirma" class="btn btn-sm bg-white border-green-600 text-green-600 hover:bg-green-100">Limpiar</label>
              <label @click="cancelarFirma" for="my-modal-2" class="btn btn-sm">CANCELAR</label>
            </div>
          </div>
        </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { storageRef } from "../main";
import download from "downloadjs"
import { degrees, PDFDocument, rgb, StandardFonts,  } from 'pdf-lib'
import Toast from "awesome-toast-component"
import SignaturePad from "signature_pad"
import moment from "moment"
import { baseConnect, baseConnectPut } from "../db/db"
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomePage',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar
  },
  data(){
    return{
      data : [],
      signaturePad : "",
      firma : "",
      nie : "",
      index : "",
      date : "",
      pdfBytes : "",
      dataFile : "",
      documents : true
    }
  },
  async mounted(){
    let hoy = moment().format("MM-YYYY")
    let datos = await baseConnect(`sign/historial/${hoy}`)
    this.data = datos
    let datosArray = Object.values(datos)
    datosArray.forEach(element => {
      if(element.sign === false){
        this.documents = false
      }
    })

    const canvas = document.querySelector("#canvas")
    this.signaturePad = new SignaturePad(canvas)
  },
  methods:{
    cancelarFirma(){
      this.signaturePad.clear()
      new Toast("Firma cancelada. 😕")
    },
    firmar(nie, index, fecha, dataFile){
      this.nie = nie
      this.date = fecha
      this.index = index
      this.dataFile = dataFile
    },
    async guardarFirma(){
      if(this.signaturePad.isEmpty()){
        new Toast("Firma vacía. 🧐")
      }else{
        new Toast("Firmando documento, espere... ⏳")
        this.firma = this.signaturePad.toDataURL();
        let document = ""
        let url = `https://asesor-27bd7-default-rtdb.europe-west1.firebasedatabase.app`
        let dateDeff = this.date.substr(3,7)
        let datos = await baseConnect(`sign/historial/${dateDeff}/${this.index}/link`)
        await fetch(`${datos}`)
          .then( data => {
            return data.arrayBuffer()
          })
          .then(async data => {
            document = data
            const pdfDoc = await PDFDocument.load(document)
            const pngImage = await pdfDoc.embedPng(this.firma)
            const pages = pdfDoc.getPages()
            const pageForSign = pages[(pages.length) - 1]
            const { width, height } = pageForSign.getSize()
            pageForSign.drawImage(pngImage , {
              x: 0,
              y: 0
            })
            this.pdfBytes = await pdfDoc.saveAsBase64()
            let pdfBytesDeff = await pdfDoc.save()
            this.pdfBytes = `data:application/pdf;base64,${this.pdfBytes}`
            await this.guardarDocumento(this.pdfBytes)
            await baseConnectPut(`sign/historial/${dateDeff}/${this.index}/sign`, true)

            download(pdfBytesDeff, "firma.pdf", "application/pdf");
            new Toast("Documento firmado con éxito. 🙌🙌")
            
          })
          await this.recargar()
      }
    },
    async guardarDocumento(file){
        await storageRef.child(`${this.nie.toUpperCase()}/firmas`).child(this.dataFile).putString(file, 'data_url')
        const urlDescarga = await storageRef.child(`${this.nie.toUpperCase()}/firmas`).child(this.dataFile).getDownloadURL()
        return urlDescarga
    },
    limpiarFirma(){
      this.signaturePad.clear()
      new Toast("Firma limpiada. 🤓")
    },
    async recargar(){
      this.limpiarFirma()
      this.documents = true
      new Toast("Recargando, espere.")
      let hoy = moment().format("MM-YYYY")
      let datos = await baseConnect(`sign/historial/${hoy}`)
      this.data = datos
      let datosArray = Object.values(datos)
      datosArray.forEach((element,index) => {
        if(element.sign === false){
          this.documents = false
          new Toast("Nuevo documento encontrado. 🥳")
        }else{
          if(index === (datosArray.length - 1)){
            if(this.documents === true){
                  new Toast("Ningun documento para firmar nuevo encontrado. 😟")
            }
          }
        }
      })

    }
  }
});
</script>


